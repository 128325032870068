import React from "react";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Grid, Typography, styled, css, Button, IconButton } from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import AddBoxIcon from "@mui/icons-material/AddBox";
import TextField from '@mui/material/TextField';
import MUIRichTextEditor from 'mui-rte'



const PlacementContainer = styled("div")(
  ({ theme }) => css`
    .main-heading {
      background: ${theme.palette.primary.main};
      width: 100%;
      display: inline-block;
      font-size: 18px;
      font-weight: 400;
      padding: 15px;
      color: #fff;
      margin-top: 15px;
    }
    .details-p{
      margin-left: 24px;
      margin-bottom: 10px;
      font-size: 15px;
    }
    .bold-heading{
      padding-right: 15px;
      line-height: 2.8;
      text-align: right;
      font-weight: 500;
      color: ${theme.palette.primary.main};
    }
    .index-icon{
      font-size: 14px;
      position: relative;
      top: 2px;
      margin-right: 8px;
    }
    .tableContainer{
      box-shadow:none;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
    .rightM {
      margin-right: 6px;
      color: ${theme.palette.primary.main};
      padding: 8px 11px;
    }
    #mui-rte-root{
      height: 300px;
      border: 1px solid #c4c4c4;
      margin-top: 10px;
      padding: 0 15px;
      border-radius: 4px;
    }
    .summary123 {
        .MuiAccordionSummary-content{
          position: relative;
          &:before{
            position: absolute;
            width: 100%;
            height: 57px;
            background: #fff0;
            content:'';
            z-index: 99;
          }
        }
        .dummy-data{
            display: flex;
            padding-left: 50px;
            width: 100%;
            -webkit-transition: .3s ease-in-out;
            -moz-transition: .3s ease-in-out;
            -o-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
            .padding-r50{
              padding-right: 50px;
            }
          }
        .Mui-expanded{
          .dummy-data{
            opacity: 0;
          }
        }
    }
  `
);

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<AddCircleOutlineIcon sx={{ fontSize: '1rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '0 16px 16px 16px',
}));


const rows = [
  createData(
    "ALAW",
    "1000",
    <IconButton
      aria-label="delete"
      color="error"
      size="large"
      sx={{ float: "right" }}
    >
      <DeleteIcon />
    </IconButton>
  ),
  createData(
    "RasiTitle",
    "200",
    <IconButton
      aria-label="delete"
      color="error"
      size="large"
      sx={{ float: "right" }}
    >
      <DeleteIcon />
    </IconButton>
  ),
  createData(
    "ABC Firm",
    "10",
    <IconButton
      aria-label="delete"
      color="error"
      size="large"
      sx={{ float: "right" }}
    >
      <DeleteIcon />
    </IconButton>
  ),
];

const save = (data) => {
  console.log(data);
};

function createData(firm, capacity, IconButton) {
  return { firm, capacity, IconButton };
}


export default function AutoPlacement() {

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <PlacementContainer>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} className="height_for_panel">

                <Typography variant="h5" component="h5" className="main-heading">
                  Auto Placement Settings
                </Typography>

                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                  <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className="summary123"
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: 500, lineHeight: 2.8 }}>
                      National settings
                    </Typography>


                    <div className="dummy-data">
                      <Typography className="bold-heading padding-r50">Allocation Method</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel disabled value="percentage" control={<Radio />} label="Percentage Allocation" />
                          <FormControlLabel disabled value="prioritization" control={<Radio />} label="Prioritization Cascade" />
                        </RadioGroup>
                      </FormControl>
                      <FormGroup sx={{flex: 1}}>
                        <FormControlLabel className="bold-heading" labelPlacement="start" control={<Switch defaultChecked />} label="Enabled" />
                      </FormGroup>
                    </div>

                  </AccordionSummary>
                  <AccordionDetails>

                     <Grid container spacing={4}>
                       <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Typography className="details-p">
                            Default placement setting will be used
                            when state-level allocations have been sent.
                          </Typography>
                          <Typography className="details-p">
                            Firms eligibility also requires licensed attorneys for the order juridiction
                          </Typography>
                       </Grid>

                       <Grid item xs={12} sm={12} md={8} lg={8}>
                          <Grid container spacing={2}>
                             <Grid item xs={12} sm={12} md={3} lg={3}>
                                <Typography className="bold-heading">Allocation Method</Typography>
                             </Grid>
                             <Grid item xs={12} sm={12} md={7} lg={7}>
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                  >
                                    <FormControlLabel value="percentage" control={<Radio />} label="Percentage Allocation" />
                                    <FormControlLabel value="prioritization" control={<Radio />} label="Prioritization Cascade" />
                                  </RadioGroup>
                                </FormControl>


                             </Grid>
                             <Grid item xs={12} sm={12} md={2} lg={2}>
                                <FormGroup sx={{flex: 1}}>
                                  <FormControlLabel className="bold-heading" labelPlacement="start" control={<Switch defaultChecked />} label="Enabled" />
                                </FormGroup>
                             </Grid>


                             <Grid item xs={12} sm={12} md={3} lg={3}>
                                <Typography className="bold-heading">Allocation</Typography>
                             </Grid>
                             <Grid item xs={12} sm={12} md={9} lg={9}>

                                <TableContainer component={Paper} className="tableContainer">
                                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Rank</TableCell>
                                        <TableCell>Law Firm</TableCell>
                                        <TableCell>Active Capacity</TableCell>
                                        <TableCell></TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {rows?.map((row, index) => {

                                        return (
                                          <TableRow
                                            key={index}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                          >
                                            <TableCell component="th" scope="row">
                                              <DensitySmallIcon className="index-icon" /> {index + 1}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                              {row.firm}
                                            </TableCell>
                                            <TableCell>
                                              {row.capacity}
                                            </TableCell>
                                            <TableCell>
                                              <IconButton
                                                aria-label="delete"
                                                color="error"
                                                size="large"
                                                sx={{ float: "right" }}
                                                onClick={() => {}}
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            </TableCell>
                                          </TableRow>
                                        )
                                    })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                             </Grid>
                             <Grid item xs={12} style={{ textAlign: "right", marginBottom:"15px" }}>
                                 <Button
                                  variant="text"
                                  endIcon={<AddBoxIcon />}
                                  size="large"
                                  color="primary"
                                  className="rightM"
                                  onClick={() => {}}
                                >
                                  Add Law Firm
                                </Button>
                              </Grid>
                               <Grid item xs={12} sm={12} md={3} lg={3}>
                                <Typography className="bold-heading" sx={{marginTop: "20px"}}>Announcement Message</Typography>
                             </Grid>
                              <Grid item xs={12} sm={12} md={9} lg={9} style={{ borderTop: "1px solid rgba(224, 224, 224, 1)", }}>
                                <TextField id="outlined-basic" fullWidth label="Subject" variant="outlined" style={{ marginTop:"15px" }} />

                                <MUIRichTextEditor
                                  label="Type something here..."
                                  onSave={save}
                                  inlineToolbar={true}
                                  className="richText"
                                />
                             </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: "right", marginBottom:"15px"}}>
                                <Button variant="contained" size="large">Save</Button>
                             </Grid>
                          </Grid>
                       </Grid>
                     </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                  <AccordionSummary
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                    className="summary123"
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: 500, lineHeight: 2.8 }}>California</Typography>

                    <div className="dummy-data">
                      <Typography className="bold-heading padding-r50">Allocation Method</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel disabled value="percentage" control={<Radio />} label="Percentage Allocation" />
                          <FormControlLabel disabled value="prioritization" control={<Radio />} label="Prioritization Cascade" />
                        </RadioGroup>
                      </FormControl>
                      <FormGroup sx={{flex: 1}}>
                        <FormControlLabel className="bold-heading" labelPlacement="start" control={<Switch defaultChecked />} label="Enabled" />
                      </FormGroup>
                    </div>

                  </AccordionSummary>
                  <AccordionDetails>

                     <Grid container spacing={4}>
                       <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Typography className="details-p">
                            Default placement setting will be used
                            when state-level allocations have been sent.
                          </Typography>
                          <Typography className="details-p">
                            Firms eligibility also requires licensed attorneys for the order juridiction
                          </Typography>
                       </Grid>

                       <Grid item xs={12} sm={12} md={8} lg={8}>
                          <Grid container spacing={2}>
                             <Grid item xs={12} sm={12} md={3} lg={3}>
                                <Typography className="bold-heading">Allocation Method</Typography>
                             </Grid>
                             <Grid item xs={12} sm={12} md={7} lg={7}>
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                  >
                                    <FormControlLabel value="percentage" control={<Radio />} label="Percentage Allocation" />
                                    <FormControlLabel value="prioritization" control={<Radio />} label="Prioritization Cascade" />
                                  </RadioGroup>
                                </FormControl>


                             </Grid>
                             <Grid item xs={12} sm={12} md={2} lg={2}>
                                <FormGroup sx={{flex: 1}}>
                                  <FormControlLabel className="bold-heading" labelPlacement="start" control={<Switch defaultChecked />} label="Enabled" />
                                </FormGroup>
                             </Grid>


                             <Grid item xs={12} sm={12} md={3} lg={3}>
                                <Typography className="bold-heading">Allocation</Typography>
                             </Grid>
                             <Grid item xs={12} sm={12} md={9} lg={9}>

                                <TableContainer component={Paper} className="tableContainer">
                                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Rank</TableCell>
                                        <TableCell>Law Firm</TableCell>
                                        <TableCell>Active Capacity</TableCell>
                                        <TableCell></TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {rows?.map((row, index) => {

                                        return (
                                          <TableRow
                                            key={index}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                          >
                                            <TableCell component="th" scope="row">
                                              <DensitySmallIcon className="index-icon" /> {index + 1}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                              {row.firm}
                                            </TableCell>
                                            <TableCell>
                                              {row.capacity}
                                            </TableCell>
                                            <TableCell>
                                              <IconButton
                                                aria-label="delete"
                                                color="error"
                                                size="large"
                                                sx={{ float: "right" }}
                                                onClick={() => {}}
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            </TableCell>
                                          </TableRow>
                                        )
                                    })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                             </Grid>
                             <Grid item xs={12} style={{ textAlign: "right", marginBottom:"15px" }}>
                                 <Button
                                  variant="text"
                                  endIcon={<AddBoxIcon />}
                                  size="large"
                                  color="primary"
                                  className="rightM"
                                  onClick={() => {}}
                                >
                                  Add Law Firm
                                </Button>
                              </Grid>
                               <Grid item xs={12} sm={12} md={3} lg={3}>
                                <Typography className="bold-heading" sx={{marginTop: "20px"}}>Announcement Message</Typography>
                             </Grid>
                              <Grid item xs={12} sm={12} md={9} lg={9} style={{ borderTop: "1px solid rgba(224, 224, 224, 1)", }}>
                                <TextField id="outlined-basic" fullWidth label="Subject" variant="outlined" style={{ marginTop:"15px" }} />

                                <MUIRichTextEditor
                                  label="Type something here..."
                                  onSave={save}
                                  inlineToolbar={true}
                                  className="richText"
                                />
                             </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: "right", marginBottom:"15px"}}>
                                <Button variant="contained" size="large">Save</Button>
                             </Grid>
                          </Grid>
                       </Grid>
                     </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                  <AccordionSummary
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    className="summary123"
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: 500, lineHeight: 2.8 }}>
                      Arizona
                    </Typography>

                    <div className="dummy-data">
                      <Typography className="bold-heading padding-r50">Allocation Method</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel disabled value="percentage" control={<Radio />} label="Percentage Allocation" />
                          <FormControlLabel disabled value="prioritization" control={<Radio />} label="Prioritization Cascade" />
                        </RadioGroup>
                      </FormControl>
                      <FormGroup sx={{flex: 1}}>
                        <FormControlLabel className="bold-heading" labelPlacement="start" control={<Switch defaultChecked />} label="Enabled" />
                      </FormGroup>
                    </div>

                  </AccordionSummary>
                 <AccordionDetails>

                     <Grid container spacing={4}>
                       <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Typography className="details-p">
                            Default placement setting will be used
                            when state-level allocations have been sent.
                          </Typography>
                          <Typography className="details-p">
                            Firms eligibility also requires licensed attorneys for the order juridiction
                          </Typography>
                       </Grid>

                       <Grid item xs={12} sm={12} md={8} lg={8}>
                          <Grid container spacing={2}>
                             <Grid item xs={12} sm={12} md={3} lg={3}>
                                <Typography className="bold-heading">Allocation Method</Typography>
                             </Grid>
                             <Grid item xs={12} sm={12} md={7} lg={7}>
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                  >
                                    <FormControlLabel value="percentage" control={<Radio />} label="Percentage Allocation" />
                                    <FormControlLabel value="prioritization" control={<Radio />} label="Prioritization Cascade" />
                                  </RadioGroup>
                                </FormControl>


                             </Grid>
                             <Grid item xs={12} sm={12} md={2} lg={2}>
                                <FormGroup sx={{flex: 1}}>
                                  <FormControlLabel className="bold-heading" labelPlacement="start" control={<Switch defaultChecked />} label="Enabled" />
                                </FormGroup>
                             </Grid>


                             <Grid item xs={12} sm={12} md={3} lg={3}>
                                <Typography className="bold-heading">Allocation</Typography>
                             </Grid>
                             <Grid item xs={12} sm={12} md={9} lg={9}>

                                <TableContainer component={Paper} className="tableContainer">
                                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Rank</TableCell>
                                        <TableCell>Law Firm</TableCell>
                                        <TableCell>Active Capacity</TableCell>
                                        <TableCell></TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {rows?.map((row, index) => {

                                        return (
                                          <TableRow
                                            key={index}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                          >
                                            <TableCell component="th" scope="row">
                                              <DensitySmallIcon className="index-icon" /> {index + 1}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                              {row.firm}
                                            </TableCell>
                                            <TableCell>
                                              {row.capacity}
                                            </TableCell>
                                            <TableCell>
                                              <IconButton
                                                aria-label="delete"
                                                color="error"
                                                size="large"
                                                sx={{ float: "right" }}
                                                onClick={() => {}}
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            </TableCell>
                                          </TableRow>
                                        )
                                    })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                             </Grid>
                             <Grid item xs={12} style={{ textAlign: "right", marginBottom:"15px" }}>
                                 <Button
                                  variant="text"
                                  endIcon={<AddBoxIcon />}
                                  size="large"
                                  color="primary"
                                  className="rightM"
                                  onClick={() => {}}
                                >
                                  Add Law Firm
                                </Button>
                              </Grid>
                               <Grid item xs={12} sm={12} md={3} lg={3}>
                                <Typography className="bold-heading" sx={{marginTop: "20px"}}>Announcement Message</Typography>
                             </Grid>
                              <Grid item xs={12} sm={12} md={9} lg={9} style={{ borderTop: "1px solid rgba(224, 224, 224, 1)", }}>
                                <TextField id="outlined-basic" fullWidth label="Subject" variant="outlined" style={{ marginTop:"15px" }} />

                                <MUIRichTextEditor
                                  label="Type something here..."
                                  onSave={save}
                                  inlineToolbar={true}
                                  className="richText"
                                />
                             </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: "right", marginBottom:"15px"}}>
                                <Button variant="contained" size="large">Save</Button>
                             </Grid>
                          </Grid>
                       </Grid>
                     </Grid>
                  </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right", marginBottom:"15px" }}>
               <Button
                variant="text"
                endIcon={<AddBoxIcon />}
                size="large"
                color="primary"
                className="rightM"
                onClick={() => {}}
              >
                Add Allocation Exception
              </Button>
            </Grid>
        </Grid>
    </PlacementContainer>
  );
}
